
export function createSummary(content) {
    let doc = new DOMParser().parseFromString(content, 'text/html');
    
    let summary = "";
    let lineCount = 0;
    const MAX_SUMMARY_HTML_LENGTH = 2999;
    const MAX_SUMMARY_TEXT_LENGTH = 950;

    for( let child of doc.body.children) {
        if( child.outerHTML.length + summary.length > MAX_SUMMARY_HTML_LENGTH ) {
            if(summary.length == 0 && child.innerText.length > 0){
                let summaryText = child.innerText;
                if(summaryText.length > MAX_SUMMARY_TEXT_LENGTH){
                    summaryText = summaryText.substring(0, MAX_SUMMARY_TEXT_LENGTH);
                }
                const index = summaryText.lastIndexOf(' ');
                if(index > 901 && index <= summaryText.length){
                    summary = summaryText.substring(0, index);
                } else {
                    summary = summaryText;
                }
            }
            break;
        }
        else {
            summary += child.outerHTML;
        }
        
        if ( lineCount > 10 ) {
            break;
        }
        ++lineCount;
    }
    return summary;
}
<template>
    <PillMenuBar :navigationItems="menuItems" headerType="main">
        <template #custom>
            <Button @click="$router.back(-1)" class="go-back-button green-button__secondary">
                <span>Go Back</span>
            </Button>
            <Button @click="createNewNote" class="create-new-button green-button__secondary">
                <span>Create New</span>
            </Button>
        </template>
    </PillMenuBar>
    <div class="tab-div">
        <div class="p-grid">
            <div class="left-col p-col-12 p-md-7">
                <div class="card">
                    <div style="padding: 5px; border-radius: 30px" id="noteFormContainer">
                        <div class="status-form-top-row">
                            <UserAvatar :imageSrc="$store.state.users.user.analyst.avatar" customStyle='width: 40px; height: 40px; margin-right: 16px;' />
                            <InputText type="text" v-model="title" placeholder="Add a title" ref="titleInput" id="noteTitleInput" maxlength="100"/>
                        </div>
                    </div>
                </div>
               
                <vue-editor id="noteEditor" ref="noteEditor" v-model="content" :editorOptions="createNoteEditorSettings('#noteFormContainer')"  
                                :customModules="editorModules" 
                               
                                />
                <div class='note-action-buttons'>
                    <Button @click="onSaveNoteClicked" class="save-note-button">
                        <span>Save</span>
                    </Button>
                    <Button @click="onPostNoteClicked" class="action-button">
                        <span>Post</span>
                    </Button>
                </div>
                 
            </div>
            <div class="right-col p-col-12 p-md-5">
                <div class="card">
                    <div class='library-container'>
                        <div>
                            <div class="library-header-title"><span class="title-text">Library</span><br /></div>
                            <SearchBar @search-input="searchInput" placeholderText='Search your note library' />
                        </div>

                        <DataTable :value="notes" class="p-datatable-customers" :lazy="true" :paginator="true" :rows="limitPerPage" :totalRecords="totalRecords" @page="onPage($event)">
                                <LoadingSpinner :loading='loadingNotes' v-if='loadingNotes' />
                            <Column style="width: 70%;">
                                <template #body="slotProps">
                                    
                                    <span class='note-title'>{{ slotProps.data.title }}</span>
                                    <template v-if="slotProps.data.postedDate">
                                        <br><span class='note-posted-date'>Posted on: {{slotProps.data.postedDate}}</span>
                                    </template>
                                </template>
                            </Column>

                            <Column bodyStyle="text-align: right">
                                <template #body="slotProps">
                                    <Button icon="pi pi-pencil" class="edit-note-button" @click="editNote(slotProps.data)" />
                                    <Button icon="pi pi-trash" class="delete-note-button" @click="deleteNote(slotProps.data)" /> </template
                            ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ConfirmModal  :headerText='confirmDeleteText' confirmButtonLabel='Delete' @confirmed="deleteNoteConfirmed" @cancelled="deleteNoteCancelled" ref='confirmDeleteModal' />
    <Dialog v-model:visible="displayWait" :modal="true" :closable="false" class='posting-progress-modal'  id="note-progress-modal">
        <template #header>
            <div style="font-size: 2rem;margin:auto;">{{displayWaitMessage}}</div>
        </template>
        <LoadingSpinner :showLoading="displayWait" style="height: 65px;min-height:65px;"/>
    </Dialog>

    <Toast class='contact-invrs-support-toast' group="contact-invrs-support" position="center">
        <template #message>
            <div v-if="errorType === 0">
                <span>An unexpected error occurred saving your new note. Please try again or contact support@invrs.com directly or through the UI <a @click='onLinkClick'>here</a>.</span>
            </div>
            <div v-else-if="errorType === 1">
                <span>An unexpected error occurred updating your note. Please try again or contact support@invrs.com directly or through the UI <a @click='onLinkClick'>here</a>.</span>
            </div>
            <div v-else-if="errorType === 2">
                <span>An unexpected error occurred posting your note. Please try again or contact support@invrs.com directly or through the UI <a @click='onLinkClick'>here</a>. </span>
            </div>
            <div v-else>
                <span>An unexpected error occurred deleting your note. Please try again or contact support@invrs.com directly or through the UI <a @click='onLinkClick'>here</a>. </span>
            </div>
        </template>
    </Toast>

    <SupportTicketModal ref="supportTicketModal" />
    <ChooseThumbnailDialog ref="thumbnailDialog" @thumbnail-selected="onThumbnailSelected"/>
    <!-- <AddTagsDialog ref="addTagsDialog" @set-selected-tags="setSelectedTags" @post-to-main-feed="saveAndPostNote" @show-post-locations-dialog="showPostLocationsDialog"/> -->
    <PostLocationsDialog ref="postLocationsDialog" @post-to-locations="saveAndPostNote"/>
     <ConfirmModal ref="confirmLoseForNewModal" @confirmed="onLoseForNewConfirmed" :headerText="'Unsaved Changes '" confirmButtonLabel="Yes">
      <template #content>
        <div class="conformation-container">
         <p>You have unsaved changes. Are you sure you want to create a new note without saving? </p>
        
        </div>
      </template>
    </ConfirmModal>
    <ConfirmModal ref="confirmLoseModal" @confirmed="onLoseConfirmed" :headerText="'Unsaved Changes '" confirmButtonLabel="Yes">
      <template #content>
        <div class="conformation-container">
         <p>You have unsaved changes. Are you sure you want to open another note without saving? </p>
        
        </div>
      </template>
    </ConfirmModal>
</template>

<script>

import PillMenuBar from '../components/menu/PillMenuBar';
import UserAvatar from '../components/profile/UserAvatar';
import Dialog from 'primevue/dialog';

import { VueEditor } from "vue3-editor";
import { createNoteEditorSettings, noteEditorModules, gatherMentionsAndMedia, QuillAutoSaveHandler} from "../components/editor/config.js";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ResearchService from '../service/ResearchService';
import ConfirmModal from '../components/modal/ConfirmModal.vue';
import LoadingSpinner from '../components/common/LoadingSpinner';
import SearchBar from '../components/query/SearchBar.vue';
import SupportTicketModal from '../components/modal/SupportTicketModal.vue';
//import AddTagsDialog from '../components/feed/AddTagsDialog.vue';
import ChooseThumbnailDialog from '../components/feed/ChooseThumbnailDialog.vue';

import PostLocationsDialog from '../components/feed/PostLocationsDialog.vue';

import FeedService from '../service/FeedService';

import StringUtils from '../utilities/StringUtils';


import { utcToZonedTime } from 'date-fns-tz';
import moment from "moment";
import EventBus from '../event-bus';
import { createSummary } from '../components/notes';

export default {
    components: {
    PillMenuBar,
    DataTable,
    Column,
    Dialog,
    ConfirmModal,
    LoadingSpinner,
    VueEditor,
    UserAvatar,
    SearchBar,
    SupportTicketModal,
    //AddTagsDialog,
    PostLocationsDialog,
    ChooseThumbnailDialog
},

    mounted() {
        console.log("****Notes mounted");
        /* This will ensure that whatever content you typed out will be not erased
        whenever you refresh the browser and leave the notes page */
        this.$watch((vm) => [vm.title, vm.content],
            () => {
                this.updateNoteModel();
                this.$store.commit("SET_CURRENT_NOTE", this.note);
            }, {
                deep: true
            })

        this.reloadNotes(this.limitPerPage, 0);
    },
    activated() {
        // if( this.editorRef ) {
        //     this.addQueuedImages();
        // }
        console.log("Notes activation called")
        this.onEditorReady();
    },
    deactivated(){
        if( this.autoSaveHandler ){
            this.autoSaveHandler.stopAutoSaveTimer();
             this.autoSaveHandler = null;  ///don't call dispose otherwise we lose unsaved changes!
        }
    },

    data() {
        return {
            menuItems: [{ label: 'My Notes', to: '/notes' }],
            editorMenuItems: [],
            content: '',
            note: null,
            limitPerPage: 10,
            currentOffset: 0,
            title: '',
            summary: '',
           
            noteFilter: null,
            notes: [],
            
            totalRecords: 0,
            recordsShowing: 0,
            
            loadingNotes: false,
            editorRef: null,
            displayWait: false,
            displayWaitMessage: "",
            
            confirmDeleteText: "",
            noteToDelete: null,

            errorType: null, // Used for handling what kind of toast message to output depending on the call that sent an error
            createNoteEditorSettings: createNoteEditorSettings,
            editorModules: noteEditorModules,
            thumbnailUrl: null,
            thumbnailsEnabled: true,
            autoSaveHandler: null,
           
        };
    },

    watch: {
        content(newVal){
             if( this.autoSaveHandler){
                this.autoSaveHandler.cacheUnsavedContentChange(newVal);
            }
        }
    },

    methods: {
        searchInput(val) {
            this.noteFilter = val;
            this.reloadNotes(this.limitPerPage, 0);
        },

         getPostDate(postedDate) {
            const nyTimeZone = 'America/New_York';
            let d = new Date(postedDate + 'Z');

            return moment(utcToZonedTime(d, nyTimeZone)).fromNow();
        },
        createNewNote() {
             if(this.autoSaveHandler?.hasUnsavedNoteChanges()){
                this.$refs.confirmLoseForNewModal.open();
             }
             else {

                this.doCreateNewNote();
             }
        },
        onLoseForNewConfirmed() {
           
            this.doCreateNewNote();
        },

        
        doCreateNewNote(){
            if(this.autoSaveHandler ){
                this.autoSaveHandler.dispose();
               
            }
            this.$refs.titleInput.$el.focus();
            document.getElementById('noteTitleInput').focus();

            // this.title = '';
            // this.summary = '';
            // this.content = '';

            // this.selectedTags = [];

            this.resetNote();
           
            this.allTags = null;
            this.note = {
                noteId: null,
                analystId: this.$store.state.users.user.analyst.analystId,
                title: this.title,
                summary: this.summary,
                content: this.content,
                noteTags: this.tags,
                postedDate: null,
            };
            let getContent = () => {
              return this.content;
              
            };
            this.autoSaveHandler = new QuillAutoSaveHandler("notes", {getContent: getContent}, {type: 'note', noteId: this.note.noteId, title: this.title, content: this.content});
            
           
           
            // this.$store.commit("SET_CURRENT_NOTE", null);
        },

        /** 
         * When user clicks copy to note from snapshot component it adds the url to the noteImageQueue.
         * When the notes view is loaded it inserts any queued images after loading the current note.
         * If there is no current note and there are images in the queue then a new unsaved note is created.
         */
        addQueuedImages() {
            console.log("checking for queued images")
            if( this.$store.state.noteImageQueue && this.$store.state.noteImageQueue.length > 0 ) {
              console.log("adding queued images:", this.$store.state.noteImageQueue)
                if( !this.note ) {
                    this.createNewNote();

                }
                // this.editorRef.model.change( writer => {
                //     writer.setSelection( writer.createPositionAt( this.editorRef.model.document.getRoot(), 'end' ) );
                // } );
                // this.editorRef.execute( 'insertParagraph', {position: this.editorRef.model.document.selection.getLastPosition()});
                // this.editorRef.execute( 'insertImage', { source: this.$store.state.noteImageQueue} );

                // this.content += '<img src="'+this.$store.state.noteImageQueue +'"/>'
                this.$store.state.noteImageQueue.forEach( src => {
                   this.content += '<img src="'+src+'"/>\n';
                    // var delta = this.$refs.noteEditor.quill.getContents();
                    //  console.log("adding queued image:"+src +"  at index "+delta.ops.length)
                    // this.$refs.noteEditor.quill.insertEmbed(delta.ops.length, 'image',src);
                })
                

                this.$store.commit('CLEAR_NOTE_IMAGE_QUEUE');
            }
        },

        onEditorReady() {
            //eslint-disable-line
            let getContent = () => {
              return this.content;
              
            };
            this.autoSaveHandler = new QuillAutoSaveHandler("notes", {getContent: getContent});
            
            if(this.autoSaveHandler.hasUnsavedNoteChanges()){
                // unsaved changes so load this
                console.log("attempting to restore unsaved note changes")
                let unsaved = this.autoSaveHandler.getUnsavedChanges();

                this.title = unsaved.title;
                //console.log("setting content to ", unsaved.content);
                this.content = unsaved.content;
               

                try{
                    this.addQueuedImages();
                }catch(error){
                    console.log("error getting queued images:", error)
                }

            }
            else {
                
                let currentNote = this.$store.state.currentNote;
            
                if( currentNote) {
                    
                
                    this.note = currentNote;
                    this.summary = this.note.summary;
                    this.content = this.note.content;
                    this.title = this.note.title;
                    this.tags = this.note.tags;
                    
                    
                    
                }
                this.addQueuedImages();
                
            }
                       
        },


        // onTextChange(delta, prevDelta, source) {// eslint-disable-line
           
     
        //     if( this.autoSaveHandler  ){
        //          //console.log("ontext change", delta, prevDelta, source)
        //          this.autoSaveHandler.cacheUnsavedNote(this.note?.noteId, this.title, delta, prevDelta);
        //     }
            
        
        // },

       

        onPostNoteClicked() {
            if(this.validateNote()) {
                this.chooseThumbnail();
                
            }
        },

        chooseThumbnail() {
            this.$refs.thumbnailDialog.open();
        },

        onThumbnailSelected(selection) {
            this.thumbnailUrl = selection.thumbnailUrl;
            this.thumbnailsEnabled = selection.thumbnailsEnabled;
            //this.showAddTagsDialog();
           // if()
            this.showPostLocationsDialog();
        },

        // showAddTagsDialog() {
        //     this.selectedTags = null;
        //     this.$refs.addTagsDialog.open();
        // },

        setSelectedTags(selectedTags) {
            this.selectedTags = selectedTags;
        },

        showPostLocationsDialog() {
            this.$refs.postLocationsDialog.open();
        },

        saveAndPostNote(postToMainFeed, selectedGroupId, selectedGroupFeedId) {
            this.summary = createSummary(this.content);

            let mentionsAndMedia = this.gatherMentionsAndMediaFromNote();
            if (this.selectedTags && this.selectedTags.length > 0) {
                mentionsAndMedia.mentions = mentionsAndMedia.mentions.concat(this.convertToMentions(this.selectedTags));
            }
            // console.log("saveAndPostNote: ", selectedTags, postToMainFeed, selectedGroup, selectedGroupFeedId, mentionsAndMedia);

            this.saveNote(() => {
                this.postSavedNote(postToMainFeed, selectedGroupId, selectedGroupFeedId, mentionsAndMedia);
            });
        },

        postSavedNote(postToMainFeed, selectedGroupId, selectedGroupFeedId, mentionsAndMedia) {
            let notePosting = { 
                noteId: this.note.noteId, 
                mentions: mentionsAndMedia.mentions, 
                mediaUrls: mentionsAndMedia.mediaUrls, 
                postToMainFeed: postToMainFeed,
                groupId: (selectedGroupId ? selectedGroupId : null),
                groupSubFeedId: (selectedGroupFeedId ? selectedGroupFeedId : null),
                thumbnailsEnabled: this.thumbnailsEnabled,
                thumbnailUrl: this.thumbnailUrl,
            }
            //console.log("posting note : ", notePosting, selectedGroupId, selectedGroupFeedId)
            this.displayWait = true;
            this.displayWaitMessage = "Posting Note...";
           
           
            FeedService.postNote(notePosting).then((resp) => { 
                this.displayWait = false;
                if( resp.data.status == "success") {
                    this.note['postedDate'] = resp.data.postedDate;
                    this.$toast.add({ severity: 'success', summary: 'Note posted.', life: 1500, group: 'center' });
                    EventBus.emit('refresh-feed');
                    for( var i =0 ; i < this.notes.length; ++i ){
                        if(this.notes[i].noteId == this.note.noteId){
                            this.notes[i].postedDate = resp.data.postedDate;
                            break;
                        }
                    }
                    this.resetNote();
                    
                    if (selectedGroupId) {
                        EventBus.emit("track-postedNote", "group");
                    }

                    if (postToMainFeed) {
                        EventBus.emit("track-postedNote", "feed");
                    }

                }
                else if( resp.data.message == 'Posting disabled'){
                     this.$toast.add({ severity: 'error', summary: 'Posts are currently disabled for one or more of the selected locations.', life: 3000, group: 'center' });
                }else {
                    console.log("error posting note " + JSON.stringify(resp.data));
                    this.openCustomErrorToast(2);
                }
                
            }).catch( error => {
                console.log("error posting note "+ error);
                this.displayWait = false;
                this.openCustomErrorToast(2);
            });
        },

        convertToMentions(selectedTags) {
            return selectedTags.reduce(this.addMentionAttributes, [])
        },
        addMentionAttributes(newArr, tag) {
            tag['mentionType'] = 'tag';
            tag['id'] = tag.name;
            newArr.push(tag);

            return newArr;
        },

        gatherMentionsAndMediaFromNote() {
           return gatherMentionsAndMedia(this.$refs.noteEditor);
        },

        resetNote() {
            this.title = '';
            this.summary = '';
            this.content = '';
            this.note = null;

            //this.$refs.addTagsDialog.resetNote();

            this.$store.commit("SET_CURRENT_NOTE", null);
        },

        onPage(evt) {
            this.reloadNotes(this.limitPerPage, evt.first);
        },

        reloadNotes(limit, offset) {
            this.loadingNotes = true;
            this.currentOffset = offset;
            if( this.noteFilter && this.noteFilter.length > 0 ){
                ResearchService.getNotes(limit, offset, "title", this.noteFilter)
                    .then((response) => {
                        this.notes = response.data.results.data;
                        this.totalRecords = response.data.results.totalCount;
                        this.recordsShowing = response.data.results.data.length;
                        this.loadingNotes = false;
                    })
                    .catch((error) => {
                        this.loadingNotes = false;
                        console.debug('There was an error:', error.response);
                    });
            }
            else {
                ResearchService.getNotes(limit, offset)
                    .then((response) => {
                        this.notes = response.data.results.data;
                        this.totalRecords = response.data.results.totalCount;
                        this.recordsShowing = response.data.results.data.length;
                        this.loadingNotes = false;
                    })
                    .catch((error) => {
                        this.loadingNotes = false;
                        console.debug('There was an error:', error.response);
                    });
            }
        },

        async editNote(data, callback) {
           if( this.autoSaveHandler?.hasUnsavedNoteChanges()){
                this.$refs.confirmLoseModal.open({data: data, callback: callback});
           }
           else {
            this.doEditNote(data, callback);
           }
        },

         onLoseConfirmed(callbackState) {
            
            this.doEditNote(callbackState.data, callbackState.callback);
            
        },

        async doEditNote(data, callback){
           
            this.autoSaveHandler.dispose();
            this.resetNote();

            await ResearchService.getNote(data.noteId).then((resp) => {
                if( resp.data.status == "success"){
                   
                    
                    this.note = resp.data.note;
                    
                    this.summary = this.note.summary;
                    this.content = this.note.content;
                    this.title = this.note.title;
                    this.tags = this.note.tags;
                    //this.$refs.noteEditor.quill.setContent(this.note.content, "silent");

                   
                    this.$store.commit("SET_CURRENT_NOTE", data);
                    if( callback ){
                        callback();
                    }
                    
                    let getContent = () => {
                        return this.content;
                    
                    };
                    this.$nextTick(()=>{
                        this.autoSaveHandler = new QuillAutoSaveHandler("notes", {getContent: getContent}, {type: 'note', noteId: this.note.noteId, title: this.title, content: this.content});
                    })
                    
                    
                }
            });
        },
        deleteNoteCancelled() {
            this.noteToDelete = null;
            this.confirmDeleteText = "";
        },

        deleteNoteConfirmed() {
            this.doDeleteNote();
        },

        deleteNote(data) {
            this.noteToDelete = data;
            this.confirmDeleteText = "Delete '" +data.title + "'";
            this.$refs.confirmDeleteModal.open();
        },

        doDeleteNote(){
            if( this.noteToDelete) {
               
                ResearchService.deleteNote(this.noteToDelete.noteId).then((resp) => {
                    if( resp.data.status == "success") {
                        if (this.note) {
                            if (this.note.noteId == this.noteToDelete.noteId) {
                                this.note = null;
                                this.content = '';
                                this.title = '';
                                this.tags = [];
                                this.summary = '';
                                this.$store.commit("SET_CURRENT_NOTE", null);
                            }
                            if( this.autoSaveHandler ){
                                this.autoSaveHandler.clearUnsavedChanges();
                            }
                        }

                        if (this.recordsShowing == 1) {
                            this.reloadNotes(this.limitPerPage, 0); // just deleted only record on current page so reload from start
                        } else {
                            this.reloadNotes(this.limitPerPage, this.currentOffset);
                        }
                        this.$toast.add({ severity: 'success', summary: 'Note deleted.', detail: '', life: 1000, group: 'center' });
                    }
                    else {
                        console.log("Error deleting note : "+ JSON.stringify(resp.data));
                        this.openCustomErrorToast(3);
                    }
                })
                .catch(error => {
                    console.log("Error deleting note : "+error);
                    this.openCustomErrorToast(3);
                });
            }
        },

        updateNoteModel() {
            if (this.note == null) {
                this.note = {
                    noteId: null,
                    analystId: this.$store.state.users.user.analyst.analystId,
                    title: this.title,
                    summary: StringUtils.stripNonPrintableCharacters(this.summary),
                    content: this.content,
                    noteTags: this.tags,
                };
            } else {
                this.note.title = this.title;
                this.note.summary = StringUtils.stripNonPrintableCharacters(this.summary);
                this.note.content = this.content;
                this.note.noteTags = this.tags;
            }
        },

        validateNote() {
            if (this.note.title == null || this.note.title.trim() == '') {
                this.$toast.add({ severity: 'error', summary: 'Note must have a title.', detail: '', life: 1000, group: 'center' });
                return false;
            } else {
                return true;
            }
        },

        onSaveNoteClicked() {
            this.saveNote();
        },

        saveNote(callback) {
            this.updateNoteModel();

            if (this.validateNote()) {
                if (this.note.noteId == null) {
                    this.saveNewNote(callback);
                } else {
                    this.updateNote(callback);
                }
            }
        },

        saveNewNote(callback) {
            this.displayWait = true;
            this.displayWaitMessage = "Saving note...";
            ResearchService.saveNote(this.note).then((resp) => {
                 this.displayWait = false;
                if( resp.data.status == "success") {
                    this.autoSaveHandler.clearUnsavedChanges();
                    this.note.noteId = resp.data.noteId;
                    this.$store.commit('SET_CURRENT_NOTE', this.note);
                    this.reloadNotes(this.limitPerPage, this.currentOffset);
                    if (callback) {
                        callback();
                    } else {
                        this.$toast.add({ severity: 'success', summary: 'Note saved.', detail: '', life: 1000, group: 'center' });
                    }

                    
                }
                else if( resp.data.message == "Invalid Name"){
                    this.$toast.add({ severity: 'error', summary: 'Invalid Title.  Please change your note title.  Only ascii printable characters allowed.', detail: '', life: 3000, group: 'center' });
                } else{
                    console.log("error saving new note: "+ JSON.stringify(resp.data));
                    this.openCustomErrorToast(0);
                }
            }).catch(error => {
                console.log("error saving new note "+error);
                this.displayWait = false;  
                this.openCustomErrorToast(0);
            });
        },

        updateNote(callback) {
            this.displayWait = true;
            this.displayWaitMessage = "Updating note...";
            ResearchService.updateNote(this.note).then((resp) => {
                this.displayWait = false; 
                if( resp.data.status == "success"){
                    this.autoSaveHandler.clearUnsavedChanges();
                    this.reloadNotes(this.limitPerPage, this.currentOffset);
                    if (callback) {
                        callback();
                    } else {
                        this.$toast.add({ severity: 'success', summary: 'Note updated.', detail: '', life: 1000, group: 'center' });
                    }
                }
                else if( resp.data.message == "Invalid Name"){
                    this.$toast.add({ severity: 'error', summary: 'Invalid Title.  Please change your note title.  Only ascii printable characters allowed.', detail: '', life: 3000, group: 'center' });
                }
                else{
                    console.log("error updating note: "+ JSON.stringify(resp.data));
                    this.openCustomErrorToast(1);
                }
            }).catch(error => {
                this.displayWait = false; 
                console.log("error updating note "+error);
                this.openCustomErrorToast(1);
            });
        },

        openCustomErrorToast(num) {
            this.errorType = num;
            this.$toast.add({severity:'error', life: 10000, group: 'contact-invrs-support'});
        },

        onLinkClick() {
            this.$refs.supportTicketModal.open();
            this.$toast.removeGroup('contact-invrs-support');
        },
    },
};
</script>
<style >
#note-progress-modal.posting-progress-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 350px;
    height: 150px;
	box-shadow: none;
}

#note-progress-modal.posting-progress-modal .p-dialog-header {
    padding: 20px 10px 10px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

#note-progress-modal.posting-progress-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

#note-progress-modal.posting-progress-modal .logo-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}

#note-progress-modal.posting-progress-modal .p-dialog-content {
	padding: 10px 25px !important;
    height: 100% !important;
    border-bottom-left-radius: 30px !important;
	border-bottom-right-radius: 30px !important;
    text-align: center;
}

#note-progress-modal.posting-progress-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>

<style scoped>
::v-deep(.go-back-button),
::v-deep(.create-new-button) {
    padding: 8px 16px;
    border-width: 2px;
    border-style: solid;
    border-radius: 28px;
}

::v-deep(*:not(.pi)), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.tab-div {
    /* background: #1f2d40; */
    /* margin: 0 12px 0; */
    border: 0 none;
    /* color: rgba(255, 255, 255, 0.87); */
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.status-form-top-row {
    display: flex;
    align-items: center;
}

::v-deep(#noteTitleInput) {
    border-radius: 10px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    padding: 8px 0px 8px 16px;
    flex: 1;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
::v-deep(#noteTitleInput:hover) {
    border-color: #bfbfbf;
}
::v-deep(#noteTitleInput:focus) {
    box-shadow: none;
    border-color: #bfbfbf;
}
::v-deep(#noteTitleInput::placeholder) {
    color: #bfbfbf;
    font-size: 16px;
}

.card {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.tab-div .card {
    border-radius: 16px;
    padding: 16px;
}
.tab-div .card:first-child {
    overflow: visible;
}
.tab-div .p-grid {
    margin: 0px;
    height: calc(100vh - 132px);
}
.tab-div [class*=-col] {
    height: 100%;
}
.tab-div .left-col {
    padding: 0px 24px 0px 0px;
}
.tab-div .left-col .card {
    margin: 0 0px 16px 0;
}
.tab-div .left-col .card:first-child {
    padding: 8px 16px;
}
.tab-div .right-col {
    padding: 0px;
}
.tab-div .right-col .card {
    margin-bottom: 16px;
}
.tab-div .right-col .card:last-child {
    height: calc(100% - 65px);
    margin-bottom: 0px;
    padding: 16px 0px;
}
.tab-div .right-col .markets-discover-header-menu {
    padding-top: 0px;
}

.library-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.library-container::-webkit-scrollbar {
	/* width */
	width: 10px;
    
}
.library-container::-webkit-scrollbar-thumb {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}

[class*=header-title] {
    /* margin-bottom: 16px; */
    /* border-bottom: 1px solid #bfbfbf; */
    width: 100%;
    padding-bottom: 16px;
}
[class*=header-title] .title-text {
    display: inline-block;
    color: #32364e;
    font-weight: bold;
    font-size: 24px;
}
.library-header-title {
    margin-left: 16px;
}

::v-deep(.pill-menu-bar.sub-header > .action) {
    margin:0;
    text-align: center;
}

::v-deep(.ck.ck-reset_all, .ck.ck-reset_all *) {
    color: #32364e;
}
::v-deep(.ck.ck-editor) {
    height: calc(100% - 148px);
}

.quillWrapper {
    height: calc(100% - 190px);
}

::v-deep(.ck.ck-editor__editable_inline) {
    border-top: 1px solid #f2f2f2;
    border: none;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}

::v-deep(.ck-editor__editable_inline::-webkit-scrollbar) {
	/* width */
	width: 8px;
   
    
}
::v-deep(.ck-editor__editable_inline::-webkit-scrollbar-track) {
	margin-right: 10px;
}
::v-deep(.ck-editor__editable_inline::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}

::v-deep(.ql-editor::-webkit-scrollbar) {
	/* width */
	width: 8px;
    
}
::v-deep(.ql-editor::-webkit-scrollbar-track) {
	margin-right: 10px;
}
::v-deep(.ql-editor::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}

::v-deep(.ck.ck-editor__main) {
    height: calc(100% - 40px);
}

::v-deep(.ck.ck-content) {
  background: #FFFFFF;
  font-size: 16px;
  color: black;
  padding: 0 30px;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  height: 100%;
  max-height: none;
}

::v-deep(.ql-editor) {
      background: #FFFFFF;
  font-size: 16px;
  color: black;
  padding: 0px 30px 15px 30px;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  height: 100%;
  max-height: none;
   box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}

::v-deep(.ck.ck-toolbar) {
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
  border-top: none;
  border-right: none;
  border-left: none;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

::v-deep(.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items) {
  margin-left: 15px;
}

::v-deep(.ck.ck-editor__top .ck-sticky-panel .ck-toolbar) {
    border-bottom-width: 1px;
}

.note-action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.save-note-button {
    font-size: 18px;
    margin-right: 16px;
    color: #32364e;
    background: #FFFFFF;
    border: 1px solid #32364e;
    border-radius: 500px;
    padding: 8px 64px;
}
.save-note-button:hover {
    color: #FFFFFF;
    background: #32364e;
    border-color: #32364e;
}
.save-note-button:focus {
    box-shadow: none;
    border-color: #32364e;
}

.action-button {
    color: #FFFFFF;
    background: #33CC99;
    border: 1px solid #33CC99;
    border-radius: 500px;
    padding: 8px 64px;
    font-size: 18px;
}
.action-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.action-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}

::v-deep(.p-datatable-thead){
    display: none;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
    padding: 0px;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td:first-child) {
    padding: 16px 0px 16px 16px;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td:last-child) {
    padding: 16px 16px 16px 0px;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td .note-title) {
    color: #32364e;
    font-size: 16px;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td .note-posted-date) {
    color: #bfbfbf;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
    color: #32364e;
}
::v-deep(.p-paginator-first:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-next:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-last:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover) {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
::v-deep(.p-paginator-first:focus),
::v-deep(.p-paginator-prev:focus),
::v-deep(.p-paginator-next:focus),
::v-deep(.p-paginator-last:focus),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus) {
    box-shadow: none;
}

::v-deep(.edit-note-button) {
    border: 1px solid #32364e;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
    flex: 0 1 47px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

::v-deep(.edit-note-button:hover) {
    border-color:#32364e;
    background-color: #32364e;
    color: #FFFFFF;
}
::v-deep(.edit-note-button:focus) {
    box-shadow: none;
    border-color: #32364e;
}


::v-deep(.delete-note-button) {
    border: 1px solid #fa0505;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
    flex: 0 1 47px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-left: 8px;
}

::v-deep(.delete-note-button:hover) {
    border-color:#fa0505;
    background-color: #fa0505;
    color: #FFFFFF;
}
::v-deep(.delete-note-button:focus) {
    box-shadow: none;
    border-color: #fa0505;
}

.contact-invrs-support-toast a:hover {
    cursor: pointer;
}

</style>

<style >
.ck.ck-content a:not([class*='mention']) {
  color: #5367FF;
  font-weight: bold;
}

.ck.ck-mentions>.ck.ck-list__item .ck-button.header {
  padding: 5px 10px 5px;
  pointer-events: none;
}
.ck.ck-mentions>.ck.ck-list__item .ck-button.header .item-header {
  font-weight: bold;
  font-size: 18px;
}
.ck.ck-mentions>.ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background: #32364e;
}
.ck.ck-mentions>.ck.ck-list__item .ck-button.header:hover,
.ck.ck-mentions>.ck-list__item:hover,
.ck.ck-mentions>.ck-list__item .ck-button.ck-on {
  background: none;
}
.ck.ck-mentions>.ck-list__item .custom-item-security.ck-button {
  padding: 13px 10px 10px;
}

.ck.ck-mentions>.ck-list__item .custom-item-tag.ck-button {
  padding: 13px 10px 10px;
}
.ck.ck-mentions>.ck-list__item .custom-item-analyst.ck-button {
  padding: 10px 10px;
}
.ck.ck-mentions>.ck-list__item .ck-button > span {
  display: block;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-symbol {
  font-size: 14px;
  color: black;
  line-height: 0.8;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-name {
  font-size: 11px;
  color: #32364e;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row {
  display: flex;
  align-items: center;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .rounded-image {
  width: 45px;
  height: 45px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #32364e;
  margin-right: 10px;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .rounded-image .profile-image {
  display: inline;
  height: 100%;
  width: auto;
}
.ck.ck-mentions>.ck-list__item:hover .ck-button .item-row .rounded-image {
  border-color: #FFFFFF;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .names-container {
  display: flex;
  flex-direction: column;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .names-container .full-name {
  font-size: 18px;
  font-weight: bold;
  color: #32364e;
  line-height: 1.2;
}
.ck.ck-mentions>.ck-list__item .ck-button .item-row .names-container .username {
  color: #a6a6a6;
  line-height: 1.2;
}
.ck.ck-mentions>.ck-list__item:hover .ck-button .item-row .names-container > span,
.ck.ck-mentions>.ck-list__item:hover .ck-button > span:not(.item-header) {
  color: #FFFFFF;
}
.ck.ck-mentions>.ck-list__item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.ck-content [class*='mention'] {
    background: none;
    font-weight: bold;
}
.ck-content .security-mention {
  color: #33CC99;
}
.ck-content .analyst-mention {
  color: #693BF5;
}


.ck-content .tag-mention {
  color: #33CC99;
}

.ck-editor__editable_inline.ck-focused {
    border-color: #33CC99 !important;
}

.embedly-card {
    max-height: 680px;
   
    }

.embedly-card-hug {
     transform-origin: top center;
    -webkit-transform:scale(0.9);
    -moz-transform-scale:scale(0.9);
    
  }

 .insta-iframe {
     height:760px;
      transform-origin: top center;
     -webkit-transform:scale(0.7);
     -moz-transform-scale:scale(0.7);
 }

 .insta-blockquote{
     max-height: 530px;
     
 }
</style>
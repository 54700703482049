<template>
    <Dialog header="Choose Thumbnail" id="chooseThumbnailDialog" class="post-details-modal" v-model:visible="display" @show="onShown" @hide="close" :modal="true" :draggable="false" >
                
        
        <div  class='edit-form-container' >
           
           
                <div class="choices-label">Thumbnails:</div>
                <div class="input-section">
                        <InputSwitch class="input-switch" v-model="thumbnailsEnabled"/>
                </div>
                <div v-show="thumbnailsEnabled">
                   
                        <div class='image-preview-thumbnail'  >
                    
                           <img :id='thumbnailElementId' :src='thumbnailUrl' v-show="thumbnailUrl" />
                            <span v-show="!thumbnailUrl" style="padding:10px;line-height:200px;">No thumbnail provided.  Will autogenerate based on images in post.</span>
                        </div>
                        
                        
                        
                         
                    
                    
                </div>
                <div v-show="thumbnailsEnabled">
                    <span class='p-button p-component action-button'  @mouseup='openFileDialogForImportingImage' style="margin-top: 25px;" v-show='!thumbnailUrl'>
                            <span class="p-button-label">Add Custom Thumbnail</span>
                            <ImageFileInput :srcImageId='thumbnailElementId' @selectedImage='selectedImage($event)' @openErrorModal='openErrorModal($event)' :maxHeight="0" :maxWidth="0" :resize="false"/>
                    </span>
                    <Button label='Remove Thumbnail' class='delete action-button' v-show='thumbnailUrl' @click='removeThumbnail' style="margin-top: 5px;margin-left:5px;"/>
                </div>
                 <div v-show="!thumbnailsEnabled"  class='image-preview-thumbnail' style="border:none;">
                    <span>No Thumbnail will be shown</span>
                </div>
                
           
            
        </div>
        <template #footer>
            <div class='button-container'>
            <Button class="post-status-button" @click="onOk" >Ok</Button>
            <Button class="post-status-button" @click="close" >Cancel</Button>  
            </div>
        </template>
        
    </Dialog>

    
    
    <ImportImageErrorModal ref='importImageErrorModal'/>
</template>

<script>

import Dialog from 'primevue/dialog';

import EventBus from '../../event-bus';

import InputSwitch from 'primevue/inputswitch';
// import CommentCard from './CommentCard';













import ImageFileInput from '../fileInput/ImageFileInput';
import ImportImageErrorModal from '../errors/ImportImageErrorModal';



export default {
    components: {
        Dialog,
        ImageFileInput,
        ImportImageErrorModal,
        InputSwitch

    },
    emits: ['thumbnail-selected'],
   
     
    data() {
        return {
           
            thumbnailUrl: null,
            thumbnailFile: null,
            thumbnailElementId: 'postNoteThumbnailId',
            thumbnailsEnabled: true,
            display: false,
            
        };
    },

    created() {
       
    },
    mounted() {
       
    },

    computed: {

        
    },

    

   

    methods: {

        openFileDialogForImportingImage() {
            EventBus.emit('open-file-dialog');
        },
        selectedImage(importedImage) {
            this.thumbnailFile = importedImage;
            this.thumbnailUrl = importedImage.url;
            this.thumbnailRemoved = false;
        },
        openErrorModal(errorMessage) {
            this.$refs.importImageErrorModal.open(errorMessage)
        },
        removeThumbnail() {
            let customThumb = document.getElementById(this.thumbnailElementId);
           
            if(  customThumb) {
                customThumb.src = ""; // Prevents former image from rendering when uploading new image
            }
           
            this.thumbnailFile = null;
            this.thumbnailUrl = null;
           
        },

        onOk() {
            this.$emit('thumbnail-selected', {thumbnailUrl: this.thumbnailUrl, thumbnailsEnabled: this.thumbnailsEnabled})
            this.close();
        },

        open() {
            this.display = true;
        },
        
        close() {
            this.display = false;
            this.thumbnailFile = null;
            this.thumbnailUrl = null;
            this.thumbnailsEnabled = true;
            
        },

       

        onShown() {
            

            
            
        },

       
    },

    name: 'ChooseThumbnailDialog',
};
</script>

<style>



.post-details-modal {
    max-height: 100vh !important;
    width: 700px;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

.post-details-modal .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
    border-bottom: 1px solid #F2F2F2;
    padding: 16px 16px;
}

.post-details-modal.editing-modal .p-dialog-content {
    overflow-y: auto;
    overflow-x: hidden;
}
.post-details-modal.editing-modal .p-dialog-content::-webkit-scrollbar {
    width: 10px;
} 
.post-details-modal.editing-modal .p-dialog-content::-webkit-scrollbar-track {
    background-color: #F2F2F2;
    opacity: 1;
    transition: background-color .2s;
} 
.post-details-modal.editing-modal .p-dialog-content::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #BFBFBF;    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
}
.post-details-modal .p-dialog-content {
    overflow: hidden;
    padding: 0px;
}

.post-details-modal .p-dialog-footer {
    border-top: 1px solid #F2F2F2;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
    padding: 16px;
}

.post-details-modal .p-dialog-header .p-dialog-header-icon {
    display: none;
}

.post-actual-content > p {
        padding: 0 ;
        margin:0;
    }

@media (max-width: 760px) {
    /* .comment-footer {
        width: auto !important;
    } */
    .comment-footer .p-avatar {
        align-self: flex-start;
        margin-top: 0.5rem;
        /* width: 36px;
        height: 36px; */
        margin-right: 3px;
    }
    .post-details-modal {
        width: 100% !important;
        height: 100% !important; /*80vh;*/
        border-radius: 0px !important;
    }
    .post-details-modal .p-dialog-header {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }    
    .post-details-modal .p-dialog-footer {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 16px;
        width: 100% !important;
    }
    .post-details-modal .p-dialog-header .p-dialog-header-icon {
        display: block;
    }  
    
    .post-actual-content > figure {
        margin-inline-start: 0 !important;
    }

    

}

 
 


</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
::v-deep(*:not(.pi, .material-symbols-outlined)), ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}



[class*='image-preview'] {
    margin-top: 8px;
    border: 1px solid black;
    border-radius: 8px;
    height: 200px;
    text-align: center;
    width: fit-content;
}
[class*='image-preview'] img {
    /* width: 100%; */
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}
.image-preview-thumbnail {
    /* width: 250px; */
    margin-left: auto;
    margin-right: auto;
}
.image-preview-thumbnail.wide-photo {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}

.image-preview-thumbnails {
    /* width: 100%; */
    margin-left:auto;
    margin-right:auto;
}
.image-preview-thumbnails > img {
    width: 50%;
}
.image-preview-thumbnails img:first-of-type {
    padding-right: 8px;
}

.edit-form-container .title-input {
    width: 100%;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.edit-form-container .title-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.view-polling-results-section {
    margin: 0 8px;
}

.question-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}
.poll {
    font-size:18px;
     left: 12px;
    top: -4px;
}

.motion-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

::v-deep(figure.table > table) {
    width: 100%;
}

.looks-container{
    margin-left: auto;
}

::v-deep(.embedly-card) {
    max-height: 680px;
    transform-origin: top center;
    -webkit-transform:scale(0.9);
    -moz-transform-scale:scale(0.9);
    }


 ::v-deep(.insta-iframe) {
     height:760px;
     
 }


 ::v-deep(.insta-blockquote){
     max-height: 500px;
     /* height: 630px; */
     transform-origin: top center;
     -webkit-transform:scale(0.7);
    -moz-transform-scale:scale(0.7);
 }

::v-deep(.ck.ck-media-form.ck-responsive-form){
    position: fixed;
    background: white;
    box-shadow: var(--ck-drop-shadow),0 0;
}

.edit-form-container {
    padding: 1rem;
}

.post-details-modal .p-dialog-header .status-post-owner-info {
    display: flex;
    align-items: center;
    width: 100%;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container {
    margin-left: 8px;
    display: block;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .full-name {
    display: block;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .full-name:hover {
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .username {
    display: block;
    color: #a6a6a6;
    font-size: 12px;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .username span:hover {
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .posted-in-span {
    color: #33CC99;
    font-size: 13px;
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .more-options {
    margin-left: auto;
}

.post-details-modal .p-dialog-content .post-body-content {
    padding: 16px;
}
.post-details-modal .p-dialog-content .post-body-content .post-content-title {
    font-weight: bold;
    font-size: 24px;
    color: #32364e;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 16px 0;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar  [class*='container'] {
    display: flex;
    align-items: center;
    color: #32364e;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar  [class*='container'] i {
    font-size: 24px;
    margin-right: 8px;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .likes-container {
    margin-right: 16px;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .likes-container .likes-amount:hover,
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .likes-container i.liked,
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .likes-container i:not(.own-post):hover {
    cursor: pointer;
    color: #33CC99;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .comments-container {
    margin-right: 16px;
}

.post-details-modal .p-dialog-content .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    overflow: auto;
    width: 100%;
    height: 100%;
}
.post-details-modal .p-dialog-content .p-scrollpanel ::v-deep(.p-scrollpanel-content::-webkit-scrollbar) {
    display: none;
}

.post-details-modal .p-dialog-footer .comment-footer ::v-deep(.post-comment-button) {
    margin: 0px;
}

.post-details-modal .p-dialog-content .comments-section {
    border-top: 2px solid #BFBFBF;
    padding: 16px;
    margin-top:10px;
}
.post-details-modal .p-dialog-footer .comment-footer {
    display: flex;
    align-items: center;
    /* width: 100%; */
}

.post-actual-content {
    word-wrap: break-word;
    font-size: 16px;
}
::v-deep(.post-actual-content p) {
    color: black;
}
::v-deep(.post-actual-content .image) {
    margin: 0 auto !important;
}

::v-deep(.post-actual-content .image.image-style-side) {
    float: right;
}
::v-deep(.post-actual-content .image img) {
    max-width: 100%;
    max-height: 100%;
    width: unset;
    height: unset;
}
::v-deep(.post-actual-content img:hover) {
    cursor: pointer;
}
::v-deep(.post-actual-content img) {         
    max-width: 100%;
    padding: 8px 0px;
}
::v-deep(a) {
    font-weight: bold;
}
::v-deep(.analyst-mention) {
    color: #693BF5;
}
::v-deep(.security-mention) {
    color: #33CC99;
}

::v-deep(.tag-mention) {
    color: #33CC99;
}
  

.more-options {
    float: right;
}

.p-avatar:hover {
    cursor: pointer;
}

.trade-details-section {
    margin-bottom: 24px;
}
.trade-details-section .trade-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}
.trade-details-section .trade-details-content ::v-deep(b),
.trade-details-section .trade-details-content b {
    color: black;
}
.trade-details-section .trade-details-content .trade-details-portfolio-name {
    color: #33CC99;
    cursor: pointer;
}
.trade-details-section .allocation-chart-section {
    display: flex;
}
.trade-details-section .allocation-chart-section ::v-deep(.allocation-card) {
    width: 50%;
    margin-left: 24px;
}

.motion-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}

.voting-deadline-section {
    margin-bottom: 16px;
}
.voting-deadline-section .voting-deadline-header {
    font-weight:bold;
    color: #32364e;
}
.voting-deadline-section ::v-deep(b) {
    color: black;
}

.summary-section {
    margin-bottom: 20px;
    font-size: 14px;
}

.summary-section-header {
    font-weight: bold;
    color: #32364E;
    font-size: 14px;
    margin-bottom: 10px;
}
.summary-section ul {
    word-wrap: break-word;
    padding-inline-start: 40px;
}

.rationale-header {
    font-weight: bold;
    color: #32364E;
    margin-bottom: 10px;
}

.motion-section {
    border-top: 1px solid #BFBFBF;
    margin: 24px 0;
    padding-top: 12px;
}

.motion-status-text {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: black;
}
.voted-text {
    color: black;
    font-size: 24px;
    font-weight: bold;
}
.voted-text ::v-deep(.yes) {
    color: #33CC99;
}
.voted-text ::v-deep(.no) {
    color: #E63E3E;
}

.motion-security-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
.motion-security-section .security-exchange-text {
    font-weight: bold;
    margin-bottom: 12px;
}
.motion-security-section ::v-deep(.p-grid) {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
}
.motion-security-section ::v-deep(.p-grid > div:first-child) {
    padding-left: 8px;
}
.motion-security-section .security-table .label-container {
    text-align: left;
}
.motion-security-section .security-table .value-container {
    font-weight: bold;
}

.voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.voting-container button {
    display: flex;
    align-items: center;
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 0px 8px;
    background: none;
}
.voting-container button:hover {
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
    animation-iteration-count: infinite;
    background: none;
    border-color: none;
    box-shadow: none;
}
.voting-container .yes-vote-button {
    margin-right: 12px;

}
.voting-container .yes-vote-button:hover {
    border-color: #33CC99;
}
.voting-container .no-vote-button:hover {
    border-color: #E63E3E;
}
.voting-container .vote-label {
    font-weight: bold;
    color: #32364e;
    font-size: 14px;
}
.voting-container .yes-vote-button .vote-icon {
    color: #33CC99;
}
.voting-container .no-vote-button .vote-icon {
    color: #E63E3E;
}
.voting-container .vote-icon {
    font-size: 24px;
}
/* .like--float {
    opacity: 0;
}
.up {
    margin-left: -5px;
    -webkit-animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    99% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
} */
.cancel-post-button {
    /* font-size: 0.8rem; */
    /* padding: 5px; */
    padding: 0.4rem 0.8rem;
   margin-left: 5px;
    font-size: 12px;
     color: #32364e;
    background: #FFFFFF;
    border: 1px solid #32364e;
    border-radius: 500px;
    /* padding: 8px 64px; */
}

.update-post-button {
    
    /* font-size: 0.8rem; */
    /* padding: 5px; */
    padding: 0.4rem 0.8rem;
     margin-left: auto;
    font-size: 12px;
     color: #FFFFFF;
    background: #33CC99;
    border: 1px solid #33CC99;
    border-radius: 500px;
    /* padding: 8px 64px; */
    
}





.cancel-post-button:hover {
    color: #FFFFFF;
    background: #32364e;
    border-color: #32364e;
}
.cancel-post-button:focus {
    box-shadow: none;
    border-color: #32364e;
}


.update-post-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.update-post-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}



.more-options-edit-mode {
    margin-left: 5px;
}

.status-container.input-error .ck-editor__editable_inline.ck-focused,
.status-container.input-error .ck.ck-content,
.status-container.input-error .ck.ck-toolbar {
  border-color: #E63E3E !important;
}

.actions-container {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.actions-container > * {
    margin-right: 8px;
}
.actions-container > *:last-child {
    margin-right: 0px;
}

.following-badge {
    font-size: 14px;
    font-weight: normal;
}

.reaction-bar-container {
    display: flex;
    margin-bottom: 8px;
}
.reaction-icon {
    color: #32364e;
}
.reaction-icon:hover {
    cursor: pointer;
    transform:rotate(0.4deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
}
.reaction-container {
    margin-right: 4px;
}
.reaction-container:last-child {
    margin-right: 0;
}
.reaction-container > span:hover {
    cursor: pointer;
}
.reaction-container.own-post > span:hover {
    cursor: default;
    pointer-events: none;
}
.reaction-container.reacted .num {
    color: #33CC99;
}
/* Shaking animation */
@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
} 



.post-details-modal > :not(.edit-form-container) > *:not(.p-dropdown, .p-inputnumber) > ::v-deep(.p-inputtext) {
    border-radius: 10px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    margin-right: 16px;
    padding: 8px 0px 8px 16px;
    flex: 1;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.post-details-modal > :not(.edit-form-container) > *:not(.p-dropdown, .p-inputnumber) > ::v-deep(.p-inputtext:hover) {
    border-color: #bfbfbf;
}
.post-details-modal > :not(.edit-form-container) > *:not(.p-dropdown, .p-inputnumber) > ::v-deep(.p-inputtext:focus) {
    box-shadow: none;
    border-color: #bfbfbf;
}
.post-details-modal > :not(.edit-form-container) > *:not(.p-dropdown, .p-inputnumber) > ::v-deep(.p-inputtext::placeholder) {
    color: #bfbfbf;
    font-size: 16px;
}

/* QUESTION DETAILS DIALOG STYLING */
.question-post-details-modal .post-content-title {
    position: relative;
    left: -15px;
}

.question-post-details-modal .title-icon {
    width: 75px;
}

.choice-text-input {
  width: 65%;
}
.question-label{
  text-align:left;
  margin-bottom: 1rem;
}

.choices-label{
  text-align:left;
  margin-bottom: 1rem;
  margin-top:1rem;
}
.gap-2 {
    gap: 1rem!important;
    margin-left: 1rem;
}

.gap-3 {
    gap: 1rem!important;
    margin-bottom: 25px;
    margin-left: 1rem;
    
}

.align-items-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap!important;
}
.flex {
    display: flex!important;
}
.ml-2{
    margin-left: 0.5rem!important;
}

.choices-section{
  text-align: left;
  margin-top: 5px;
  display: flex;
  flex-direction: column; 
}

.choices-section .p-dropdown {
    width: 13rem;
}

.contest-dropdown .p-dropdown {
    width: unset !important;
    min-width: 13rem !important;
}

.choices-labels{
  display: flex;
  justify-content: space-between;
}

.input-section {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 5px 0px 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.choices-item-container {
  flex-grow: 1;
}
.required {
  margin-left: 2px;
}

.reward-currency-select-button {
    margin-left: auto;
    width: fit-content;
}
::v-deep(.reward-currency-select-button .p-button) {
    font-size: 0.9rem !important;
    padding: 4px 6px 4px 6px;
}
::v-deep(.reward-currency-select-button .p-button:focus) {
    box-shadow: none;
}

/* MOTION DETAILS DIALOG STYLING */

.motion-post-details-modal .title-icon {
    width: 30px;
    margin-right: 15px;
}

@media (max-width: 760px) {
    ::v-deep(.scroll-to-top-button) {
        bottom: 50px;
    }

    .trade-details-section .allocation-chart-section {
        flex-direction: column;
    }
    .trade-details-section .allocation-chart-section ::v-deep(.allocation-card) {
        width: 100%;
        margin-left: 0;
    }

    .motion-status-text {
        font-size: 16px;
    }
    .voted-text {
        font-size: 16px;
    }

    .voting-container button {
        padding: 6px;
    }
    .voting-container .vote-label {
        font-size: 14px;
    }
    .voting-container .vote-icon {
        font-size: 22px;
    }
    
    .motion-security-section ::v-deep(.p-grid > div:first-child) {
        padding-right: 0;
    }
    .motion-security-section ::v-deep(.p-grid > div:last-child > .quote-data-table) {
        margin-left: 8px;
    }
    .security-exchange-text {
        font-size: 12px;
    }
    .p-grid ::v-deep(.quote-data-table .value-container > span),
    .p-grid ::v-deep(.quote-data-table .label-container > span) {
        font-size: 12px;
    }
}

@media (max-width: 592px) {

    .question-post-details-modal .title-icon {
        width: 60px;
        height: 40px;
    }

    .motion-post-details-modal .post-content-title {
        position: relative;
        left: 10px;
    }
    .motion-post-details-modal .title-icon {
        width: 20px;
    }
}

</style>

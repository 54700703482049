<template>
    <span class="p-input-icon-left search-bar-container">
        <i class="pi pi-search" />
        <InputText class='search-bar' type="text" v-model='searchText' @input="onInput" :placeholder="placeholderText"/>
        <slot name="afterInput"></slot>
    </span>
</template>

<script>
import InputText from 'primevue/inputtext';

export default {
    name: 'SearchBar',
    emits: ['search-input', 'loading'],
    props: {
        placeholderText: {
            type: String,
            default: ''
        },

        delay: { // The number of milliseconds of delay between keystrokes
            type: Number,
            default: 300
        }
    },
    components: {
        InputText
    },

    data() {
        return {
            searchText: '',
            timeout: null
        }
    },

    methods: {
        clearInput() {
            this.searchText = '';
        },

        onInput() {
            this.$emit('loading');

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.$emit('search-input', this.searchText);
            }, 300);
        }
    }


}
</script>

<style scoped>
.search-bar-container {
    display: block;
    padding: 0px 16px 16px;
    border-bottom: 1px solid #bfbfbf;
}

.search-bar-container .pi-search {
    left: 28px;
    top: 18px;
}

.search-bar {
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    padding: 8px 8px 8px 36px;
    color: #32364e;
    font-size: 16px;
	width: 100%;

}
.search-bar:hover {
    border-color: #33CC99;
}
.search-bar:enabled:focus {
    border-color: #33CC99;
    box-shadow: none;
}
</style>
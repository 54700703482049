<template> 
    <Dialog header="Contact Support" v-model:visible="display" :modal="true" :closable="true" class='contact-support-modal' @hide="close">
        <span class='body-text'>If you're having issues or have questions you can create a ticket using this form.</span>
        <template #footer>
            <span class='p-float-label'>
                <InputText id='ticket-subject' type="text" v-model="ticketSubject" class="subject-input" maxlength="30"/>
                <label for="ticket-subject" class='input-label'>Subject</label>
            </span>
            <span class='p-float-label'>
                <Textarea id="ticket-description" v-model="ticketDescription" :autoResize="false" rows="10" cols="100" class="description-input" maxlength="280"/>
                <label for="ticket-description" class='input-label'>Description</label>
            </span>
            <div class='button-container'>
                <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
                <Button label="Submit" class='accept p-button-raised p-button-rounded' @click='submitTicket' :loading="sending"/>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import UserService from '../../service/UserService';

export default {
    name: 'SupportTicketModal',
	data() {
		return {
			display: false,
            ticketSubject: null,
            ticketDescription: null,
            sending: false,
		}
	},
	components: {
		Dialog, InputText,Textarea
	},

	methods: {
        open() {
            
            this.display = true;
            this.ticketSubject = null;
            this.ticketDescription = null;
            this.sending = false;
        },

		close() {
			this.display = false;
             this.ticketSubject = null;
            this.ticketDescription = null;
            this.sending = false;
		},

       
        submitTicket() {
            this.sending = true;
            this.$toast.removeAllGroups()
            if( this.ticketSubject == null || this.ticketSubject.trim() == '' ){
                this.$toast.add({ severity: 'error', summary: 'Please enter a subject.', life: 5000, group: 'center' });
                this.sending = false;  
                
            }
            else if( this.ticketDescription == null || this.ticketDescription.trim() == '' ){
                this.$toast.add({ severity: 'error', summary: 'Please enter a description.', life: 5000, group: 'center' });
                this.sending = false;  
                return;
            }
            else {
                let ticket =  {
                    subject : this.ticketSubject,
                    content: this.ticketDescription,
                    email: this.$store.state.users.user.username
                }
                UserService.submitSupportTicket(ticket).then(resp => {
                    if( resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: 'A support ticket has been generated and will be reviewed as soon as possible.', life: 10000, group: 'center' });
                        this.close();    
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: 'An error occurred generating the support ticket.  Please try again.  If the problem persists please send an email to support@invrs.com.', life: 10000, group: 'center' });
                        this.sending = false;
                    }
                }).catch(err => {
                    console.error("Error sending ticket "+err);
                    this.$toast.add({ severity: 'error', summary: 'An error occurred generating the support ticket.  Please try again.  If the problem persists please send an email to support@invrs.com.', life: 10000, group: 'center' });
                    this.sending = false;
                });
            }
           
                
        },
       
        
	}
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.contact-support-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 40rem;
	box-shadow: none;
}

.contact-support-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

.contact-support-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.contact-support-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 25px;
    overflow-y: visible;
}
.contact-support-modal .body-text {
    font-size: 16px;
    color: #a6a6a6;
}

.contact-support-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.contact-support-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>
<style scoped>
.contact-support-modal .subject-input {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.contact-support-modal .subject-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.contact-support-modal .description-input {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.contact-support-modal .description-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.p-float-label {
    margin: 15px 0px 25px;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.contact-support-modal .p-dialog-footer .p-button {
    font-size: 16px;
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .contact-support-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 10px;
 }

 .contact-support-modal .p-dialog-footer .accept {
    border-color: #33CC99;
    padding: 10px 30px;
 }

.contact-support-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33CC99;
 }
  .contact-support-modal .p-dialog-footer .accept:focus {
     box-shadow: none;
     border-color: #33CC99;
 }

.contact-support-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
 } 
 .contact-support-modal .p-dialog-footer .cancel:focus {
     box-shadow: none;
     border-color: #32364E;
 }
</style>